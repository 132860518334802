import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { request, gql } from 'graphql-request'
import SubgraphsContext from '../context/SubgraphsContext'

const useRelayedExecutions = (id, daysAgo) => {
  const subgraphs = useContext(SubgraphsContext)

  const { data, isLoading, isError } = useQuery(
    ['useRelayedExecutions', id, daysAgo],
    () => fetchAllRelayedExecutions(id, daysAgo, subgraphs),
    {
      enabled: !!subgraphs && Object.keys(subgraphs).length > 0,
      retry: 2, // Intenta 2 veces antes de fallar
      staleTime: 1000 * 60 * 5, // Cachea la data por 5 minutos
    }
  )

  return { data, isLoading, isError }
}

const fetchRelayedExecutions = async (chainId, id, daysAgo, url) => {
  let filter = {}

  if (daysAgo) {
    const date = new Date()
    date.setDate(date.getDate() - daysAgo)
    const timestampInSeconds = Math.floor(date.getTime() / 1000)
    filter.executedAt_gte = timestampInSeconds
  }

  if (!id) return null

  try {
    let data = await request(
      url,
      gql`
        query Relayed($filter: RelayedExecution_filter) {
          environment(id: ${'"' + id.toLowerCase() + '"'}) {
            network
            namespace
            relayedExecutions(where: $filter, orderBy: executedAt, orderDirection: desc) {
              executedAt
              gasUsed
              gasPrice
              costUSD
              task {
                id
                name
                executionType
                taskConfig
              }
              transaction {
                hash
                sender
              }
              id
              index
              movements {
                added
                amount
                token {
                  decimals
                  symbol
                  id
                  name
                }
              }
              smartVault {
                environment {
                  network
                }
              }
              succeeded
            }
          }
        }
      `,
      { filter }
    )

    return data?.environment ? data.environment.relayedExecutions : null
  } catch (error) {
    console.error(`Error fetching relayed executions for chain ${chainId}:`, error)
    throw error
  }
}

const fetchAllRelayedExecutions = async (id, daysAgo, subgraphs) => {
  let data = []
  await Promise.all(Object.keys(subgraphs).map(async (chainId) => {
    try {
      const relayedByChain = await fetchRelayedExecutions(chainId, id, daysAgo, subgraphs[chainId])
      if (relayedByChain) {
        data.push(...relayedByChain)
      }
    } catch (error) {
      console.error(`Failed to fetch data from subgraph ${chainId}:`, error)
    }
  }))

  return data.sort((a, b) => b.executedAt - a.executedAt)
}

export default useRelayedExecutions
