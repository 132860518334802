import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { Container } from '../styles/texts'
import Table from '../structure/Table/Table'
import TableRow from '../structure/Table/TableRow'
import TableHeader from '../structure/Table/TableHeader'
import Breadcrumbs from '../structure/Breadcrumbs'
import Network from '../components/Network'
import EmptyState from '../components/EmptyState'
import ActivityPanel from '../sections/ActivityPanel'
import ActivityItem from '../sections/ActivityItem'
import { Heading1 } from '../styles/texts'
import { Skeleton } from '../styles/general'
import useRelayedExecutions from '../hooks/useRelayedExecutions'
import arrowDown from '../assets/arrow-down.svg'

const Activity = ({ width }) => {
  const params = useParams()
  const navigate = useNavigate()
  const [daysAgo, setDaysAgo] = useState(1)
  const [isOpen, setOpen] = useState(false)
  const [item, setItem] = useState(null)
  const { data, isLoading, isError } = useRelayedExecutions(params.id, daysAgo || 1)
  const [selectedNetwork, setSelectedNetwork] = useState('')
  const [selectedFailedReasons, setSelectedFailedReasons] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [filters, setFilters] = useState({})
  const location = useLocation()

  const large = 1000
  const buttonRef = useRef(null)


  const updateURL = (newFilters) => {
    const searchParams = new URLSearchParams()
    Object.keys(newFilters).forEach(key => {
      if (newFilters[key]) {
        searchParams.set(key, newFilters[key])
      } else {
        searchParams.delete(key)
      }
    })
    const queryString = searchParams.toString()
    const newURL = queryString ? `${location.pathname}?${queryString}` : location.pathname

    navigate(newURL, { replace: true })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const reasons = searchParams.get('reasons') || ''
    const chainId = searchParams.get('chainId') || selectedNetwork
    const daysAgo = searchParams.get('daysAgo') || 1
    setFilters({ reasons, chainId, daysAgo })
    setSelectedNetwork(chainId)
    setDaysAgo(daysAgo)
    setSelectedFailedReasons(reasons)

    updateURL({ reasons, chainId, daysAgo })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleImageClick = () => {
   buttonRef.current.dispatchEvent(new Event('click', { bubbles: true }))
  }

  const handleFailedReasons = (e) => {
   const reasons = !selectedFailedReasons
   setSelectedFailedReasons(reasons)
   updateURL({ ...filters, reasons: reasons })
  }

  const handleClick = (isOpen, item) => {
    setItem(item)
    setOpen(isOpen)
  }

  const handleSelectNetwork = (e) => {
    setSelectedNetwork(e.target.value)
    updateURL({ ...filters, chainId: e.target.value })
  }

  const handleDaysAgo = (days) => {
    setDaysAgo(days)
    updateURL({ ...filters, daysAgo: days })
  }

  const getTitle = (daysAgo) => {
    switch (daysAgo) {
      case 1:
        return 'No logs to report in the last day just yet.'
      case 7:
        return 'No logs to report in the last week just yet.'
      case 31:
        return 'No logs to report in the last month just yet.'
      default:
        return 'No logs to report in the selected period just yet.'
    }
  }

  return (
    <ActivitySection>
      <Container>
        <Breadcrumbs />
        <Heading1>Activity</Heading1>
        {isError && (
          <ErrorMessage>
            <p>⚠️ There was an error fetching task data. Please try again later.</p>
          </ErrorMessage>
        )}
        {isLoading ? (
          <>
            <br />
            <br />
            <Skeleton height="24px" width="100%" />
          </>
        ) : data.length > 0 ? (
          <>
            <Flex>
              <div className="custom-select-container">
                <select
                  value={selectedNetwork}
                  onChange={handleSelectNetwork}
                  className="custom-select"
                  ref={buttonRef}
                >
                  <option value="">All Networks</option>
                  {Array.from(
                    new Set(
                      data.map((task) => task.smartVault.environment.network)
                    )
                  ).map((network) => (
                    <option key={network} value={network}>
                      <Network network={network} noLogo={true}/>
                    </option>
                  ))}
                </select>
                <div  id="arrow-container" className="arrow-container"  onClick={handleImageClick}>
                  <img src={arrowDown} alt="Arrow" />
                </div>
              </div>
              <div className="custom-realtime-container">
                <input type="checkbox" id="checkbox" onChange={handleFailedReasons} checked={selectedFailedReasons} />
                <label htmlFor="checkbox"> Show all transactions</label>
              </div>
            </Flex>
            <TableWrapper>
              <ContainerTable
                header={
                  <TableRow>
                    <TableHeader title="When" align="left" />
                    <TableHeader title="Network" align="left" />
                    <TableHeader title="Task" align="left" />
                    <TableHeader title="Details" align="left" />
                    <TableHeader title="Cost" align="left" />
                  </TableRow>
                }
              >
                {data
                  ?.filter((action) =>
                    (!selectedNetwork || action.smartVault.environment.network === selectedNetwork) &&
                    (selectedFailedReasons || action.succeeded)
                  )
                  .map((action, i) => (
                    <ActivityItem
                      key={i}
                      item={action}
                      index={i + 1}
                      width={width}
                      showFailedReasons={selectedFailedReasons}
                      handleClick={handleClick}
                    />
                  ))}
              </ContainerTable>
            </TableWrapper>
            <ActivityPanel
              open={isOpen}
              item={item}
              onClose={() => setOpen(!isOpen)}
            />
          </>
        ) : (
          <EmptyState
            width={width}
            title={getTitle(daysAgo)}
            text="Data will appear here once the transaction is executed."
          />
        )}
        <PaginationContainer>
          <Chip onClick={(e) => handleDaysAgo(7)}>Week</Chip>
          <Chip onClick={(e) => handleDaysAgo(31)}>Month</Chip>
        </PaginationContainer>
      </Container>
    </ActivitySection>
  )
}

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media only screen and (max-width: 800px) {
    white-space: nowrap;
  }
`
const ErrorMessage = styled.div`
  background: rgba(255, 69, 58, 0.1);
  color: #ff453a;
  border: 1px solid #ff453a;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  margin: 20px 0;
`

const ContainerTable = styled(Table)`
  background-color: rgba(168, 154, 255, 0.12);
  border-radius: 24px;
  tbody tr:hover {
    background: rgba(0, 0, 0, 0.24);
  }
  th {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  }
  td:first-child,
  th:first-child {
    padding-left: 25px;
    white-space: nowrap;
  }
  td:last-child,
  th:last-child {
    padding-right: 25px;
  }
  td {
    padding: 23px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    vertical-align: top;
  }
  tr:last-child td {
    border-bottom: 0px solid;
  }
`

const ActivitySection = styled.section`
  height: auto;
  padding: 20px 0;
  color: ${(props) => props.theme.textWhite};
  width: 100%;
  margin: auto;

  .custom-select-container {
    position: relative;
    display: inline-block;
  }

  .custom-select {
    padding: 6px 13px 6px 16px;
    min-width: 150px;
    border: 0px;
    background: rgba(168, 154, 255, 0.10);
    color: white;
    border-radius: 10px;
    font-family: 'DMSans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    outline: none !important;
    margin-bottom: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .arrow-container {
    position: absolute;
    transform: translateY(-50%);
    top: 35%;
    right: 13px;
  }

  .arrow-container img {
    height: 15px;
    width: 15px;
    object-fit: contain;
    height: 100%;
  }
`

const Flex = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 30px;
`

const PaginationContainer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  gap: 30px;
`

const Chip = styled.span`
  border-radius: 24px;
  background: #6F5CE6;
  padding: 7px 12px;
  cursor: pointer;
  &:hover {
    background: #5042A5;
  }
`

export default Activity
