import React from 'react'
import styled from 'styled-components'
import spotlightbackground from '../assets/spotlight.png'
import mediumLarge from '../assets/app-landing/medium-large.svg'

const Spotlight = props => {
  return (
    <SpotlightSection
      background={spotlightbackground}
      href="https://medium.com/mimicfi/introducing-mimic-v3-diving-into-new-depths-of-efficiency-4e564ad2714a"
      rel="noreferrer"
      target="_blank"
    >
      <div>
        <h3>Mimic v3 is now live!</h3>
        <p>
          Dive into new depths of efficiency we a ton of exciting new features
        </p>
      </div>
      <div>
        <button>Read story</button>
        <p>
          Will open on <img src={mediumLarge} alt="medium" />
        </p>
      </div>
    </SpotlightSection>
  )
}

const SpotlightSection = styled.a`
  background: url(${props => props.background});
  height: auto;
  width: 100%;
  min-height: 130px;
  max-width: 1071px;
  color: white;
  padding: 25px 50px 25px 50px;
  border-radius: 24px;
  margin: 0 auto 200px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    max-width: 80vw;
    height: auto;
    padding: 15px;
    background-size: cover;
  }
  @media only screen and (max-width: 1000px) {
    padding: 25px;
    margin: 0 auto;
  }
  text-align: left;
  div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }

  color: white;
  h3 {
    font-family: 'VisbyBold';
    font-size: 40px;
    font-style: normal;
    margin: 0;
    line-height: 44px;
  }
  button {
    padding: 18px 50px;
    border-radius: 16px;
    border: 0px;
    color: #6f5ce6;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'DMSansBold';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
`


export default Spotlight
