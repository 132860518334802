import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { formatTokenAmount } from '../utils/math-utils'
import { parseActionName } from '../utils/general-utils'
import { tokens } from '../constants/tokens'
import { Heading1, StandardText } from '../styles/texts'
import Status from '../components/Status'
import Address from '../components/Address'

const ActionDetail = ({ open, onClose, item }) => {
  const chainId = item ? item.smartVault?.environment?.network : ''

  const handleWrapClick = (e) => {
    e.stopPropagation() // Evita que el clic dentro del panel lo cierre
  }

  return (
    <Detail isopen={open.toString()} onClick={onClose}>
      <div className="overlay" onClick={onClose} />
      <div className="wrap" onClick={handleWrapClick}>
        {item && (
          <>
            <CloseButton onClick={onClose}>x</CloseButton>
            <Heading1 style={{ marginTop: '15px' }}>Task details</Heading1>
            <DetailItem>
              <StandardText className="label">Task</StandardText>
              <StandardText>{parseActionName(item?.task?.name)}</StandardText>
            </DetailItem>
            <DetailItem>
              <StandardText className="label">Status</StandardText>
              <StandardText>
                <Status status={item.succeeded === true ? 'success' : 'fail'} width={760} />
              </StandardText>
            </DetailItem>
            <DetailItem>
              <StandardText className="label">Executed by</StandardText>
              <Address address={item?.transaction?.sender} chainId={chainId} short />
            </DetailItem>
            {item.movements &&
              item?.movements?.map((movement, index) => {
                const token = tokens.find(
                  (element) => element?.symbol === movement?.token?.symbol
                )
                return (
                  <DetailItem key={index} added={movement.added.toString()}>
                    <StandardText className="label">
                      Amount {movement.added ? 'in' : 'out'}
                    </StandardText>
                    <StandardText>
                      {formatTokenAmount(movement.amount, movement.token.decimals, {
                        digits: 2,
                      })}{' '}
                      {movement.token.symbol}
                      {token && <img src={token.logoURI} className="token" alt="Token" />}
                    </StandardText>
                  </DetailItem>
                )
              })}
            <DetailItem>
              <StandardText className="label">Date</StandardText>
              <StandardText>
                {moment.unix(item?.executedAt).format('MMM Do HH:mm:ss')}
              </StandardText>
            </DetailItem>
            <br />
            <br />
            <StandardText className="label">Transaction hash</StandardText>
            <Address address={item?.transaction?.hash} chainId={chainId} short type="transaction" />
            <br />
            <StandardText className="label">Task action</StandardText>
            <Address address={item?.task?.id} chainId={chainId} short />
          </>
        )}
      </div>
    </Detail>
  )
}

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
`

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 40px;
  p {
    margin: 0;
    word-break: break-word;
    display: flex;
    align-items: center;
  }
  .relative {
    position: relative;
  }
`

const Detail = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
  word-break: break-word;
  .label {
    color: ${(props) => props.theme.textGrey};
  }

  .overlay {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    will-change: opacity;
    pointer-events: none;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.3, 1);
  }

  ${(props) => props.isopen === 'true' && '.overlay { opacity: 1; pointer-events: auto;}'};

  .wrap {
    position: fixed;
    box-sizing: border-box;
    height: 100%;
    width: 504px;
    cursor: default;
    @media only screen and (max-width: 510px) {
      width: 100%;
    }
    padding: 50px;
    background: ${(props) => props.theme.panel};
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(-100%);
    will-change: transform;
    z-index: 101;
    pointer-events: auto;
    transition: transform 130ms ease-out;
    right: 0;
    transform: translateX(100%);
  }

  ${(props) =>
    props.isopen === 'true' &&
    '.wrap { transform: none; pointer-events: auto; transition: transform 330ms ease-in;}'};

  h2 {
    text-align: left !important;
  }
  .token {
    width: 26px;
    padding-left: 7px;
  }
`
export default ActionDetail
