import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { SupportedChainId } from '../constants/chainInfo'

const useSlippage = (id, chainId, startDate, endDate, refetchInterval = 0) => {
  return useQuery(
    {
      queryKey: ['slippage', (id + 'slippage'), chainId, startDate, endDate],
      queryFn: () => fetchReportData(id, chainId, startDate, endDate),
      staleTime: 1000,
      refetchInterval: false
    }
  )
}

const fetchReportData = async (id, chainId, startDate, endDate) => {
  const url = `https://api.mimic.fi/public/environments/${id}/slippage`
  try {
    const params = {
      startDate: startDate,
      endDate: endDate,
      chainIds: Object.values(SupportedChainId).map(String),
    }


    // export const SupportedChainId = {
    //   GOERLI: 5,
    //   GNOSIS: 100,
    //   MUMBAI: 80001,
    //   ZKSYNC: 324,
    //   ZKEVM: 1101,
    //   AURORA: 1313161554
    // }

    const { data } = await axios.get(url, {
      params: { ...params }
    })

    let totalSwapped = 0
    let totalSlippage = 0
    let slippageCount = 0
    if(data) {
      Object.keys(data).forEach((task) => {
        Object.keys(data[task]).forEach((day) => {
          const { averageSlippage, totalInAmountUsd } = data[task][day]
          if (averageSlippage) {
             totalSwapped += totalInAmountUsd
             totalSlippage += averageSlippage
             slippageCount++
          }
        })
      })
    }
    return { tasks: data, totalSwapped, totalSlippage, slippageCount }
  } catch (error) {
    if (error.response) {
      console.log('Error: ', error.response)
      return false
    }
    throw error
  }
}

export default useSlippage
