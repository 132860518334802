import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Breadcrumbs from '../structure/Breadcrumbs'
import TasksParams from '../sections/TasksParams'
import GeneralParams from '../sections/GeneralParams'
import Split from '../structure/Split'
import { useParams, Link } from 'react-router-dom'
import { parseActionName } from '../utils/general-utils'
import { Heading3, SmallText } from '../styles/texts'
import { Scrollbars } from 'react-custom-scrollbars'
import { ConfigContainer } from '../styles/general'
import { renderThumbVertical } from '../utils/general-utils'
import warning from '../assets/warning.svg'
import EnvironmentContext  from '../context/EnvironmentContext'

const Settings = ({ width }) => {
  const params = useParams()
  const tasks = useContext(EnvironmentContext)
  const action = params.action && tasks?.data?.tasks
    ? tasks?.data?.tasks?.filter(item => item.name === params.action)[0]
    : ''


  return (
    <SettingsSection>
      <Split
        primary={
          <Scrollbars renderThumbVertical={renderThumbVertical}>
            <ConfigContainer style={{ paddingBottom: '0' }}>
              <Breadcrumbs />
            </ConfigContainer>
            {params.action ? (
              <TasksParams task={action} />
            ) : (
              <GeneralParams />
            )}
          </Scrollbars>
        }
        secondary={<Menu tasks={tasks?.data?.tasks} />}
      />
    </SettingsSection>
  )
}

const Menu = ({ tasks = [] }) => {
  const params = useParams()
  const [active, setActive] = useState(params.action || 'general')
  const [showObsolete, setShowObsolete] = useState(false) // Estado para el acordeón

  const handleClick = (e) => {
    const index = e.target.id
    if (index !== active) {
      setActive(index)
    }
  }

  // Ordenar tareas alfabéticamente
  const sortedTasks = tasks
    .map(task => ({
      ...task,
      obsolete: parseInt(task.permissions, 10) <= 0,
    }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const nonObsoleteTasks = sortedTasks.filter(task => !task.obsolete)
  const obsoleteTasks = sortedTasks.filter(task => task.obsolete)

  return (
    <div className="scroll-container">
      <Tabs>
        <Heading3>Configuration</Heading3>
        <Link to={`/${params.id}/settings/`}>
          <Tab onClick={handleClick} id="general" active={active === 'general'}>
            <SmallText id="general">General Params</SmallText>
          </Tab>
        </Link>
        <Title>Tasks</Title>

        {/* TAREAS ACTIVAS */}
        {nonObsoleteTasks.map((task) => (
          <TabAction
            name={task.name}
            key={task.id || task.name}
            handleClick={handleClick}
            obsolete="false"
          />
        ))}

        {/* ACORDEÓN PARA TAREAS OBSOLETAS */}
        {obsoleteTasks.length > 0 && (
          <>
            <ObsoleteHeader onClick={() => setShowObsolete(!showObsolete)}>
              <Title>No Permissions Tasks</Title>
              {showObsolete ? (
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path d="M6 15L12 9L18 15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              ) : (
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none">
                  <path d="M6 9L12 15L18 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              )}
            </ObsoleteHeader>

            {showObsolete && (
              <ObsoleteTasksContainer>
                {obsoleteTasks.map((task) => (
                  <TabAction
                    name={task.name}
                    key={task.id || task.name}
                    handleClick={handleClick}
                    obsolete="true"
                  />
                ))}
              </ObsoleteTasksContainer>
            )}
          </>
        )}
      </Tabs>
    </div>
  )
}


const ObsoleteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  margin-top: 15px;
  color: white;
  font-weight: bold;
`

const ObsoleteTasksContainer = styled.div`
  margin-top: 10px;
`


const TabAction = ({ name = 'Action', permissions, handleClick, obsolete = 'false'}) => {
  const params = useParams()
  return (
    <Link to={'/' + params.id + '/settings/' + name}>
      <Tab
        onClick={handleClick}
        active={
          params.action === name ? 'true' : 'false'
        }
        id={name}
      >
        <SmallText id={name}>{obsolete === 'true' && <img src={warning} alt="!"/>}{parseActionName(name)}</SmallText>
      </Tab>
    </Link>
  )
}

const SettingsSection = styled.section`
  height: calc(100vh - 160px);
  padding: 0;
  color: ${props => props.theme.textWhite};
  width: 100%;
  display: flex;
  z-index: 0;
  table tr:last-child {
    td {
      border-bottom: 0px solid transparent;
    }
  }

`

const Title = styled(SmallText)`
  font-weight: bold;
  color: ${props => props.theme.textWhite};
`
const Tabs = styled.div`
  padding: 40px 30px;
  @media only screen and (max-width: 700px) {
    padding: 0px 30px;
  }

  height: 100%;
  box-sizing: border-box;
`

const Tab = styled.div`
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #242734;
  }
  padding: 0 10px;
  margin: 10px -10px;
  background-color: ${props =>
    props.active === 'true' ? '#242734' : 'transparent'};
  color: ${props => (props.active === 'true' ? '#fff' : '#A7acc1')};
  transition: all 0.3s ease-in-out;
  a {
    text-decoration: none !important;
  }

  &:hover {
    background-color: #242734;
    color: transparent;
    background: linear-gradient(to right, white 70%, transparent);
    -webkit-background-clip: text;
    background-clip: text;
  }

  white-space: nowrap;
  overflow: hidden;
  position: relative;
  overflow: hidden;

  color: transparent;
  background: linear-gradient(to right, #A7ACC1 70%, transparent);
  -webkit-background-clip: text;
  background-clip: text;


  p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    img {
      height: 20px;
    }
  }
`

export default Settings
